import React from 'react';
import { Divider } from '#components/Divider/Divider';
import Link from 'next/link';
import { useFirebaseCurrentUser } from '#lib/hooks/useFirebaseCurrentUser';
import 'react-multi-carousel/lib/styles.css';
import { RocketLaunchIcon } from '@heroicons/react/24/outline';

export default function CallToActionCreators() {
  const { claims } = useFirebaseCurrentUser();
  return (
    <div className="mt-24 mb-12 sm:px-24">
      <Divider />
      <div className="flex flex-col gap-4 w-full my-24 sm:my-36 px-8 sm:px-0 justify-center items-center">
        <RocketLaunchIcon className="w-24 h-24 text-gray-600 dark:gray-400" />
        <div className="text-4xl sm:text-6xl text-gray-900 dark:text-white font-bold text-center lg:text-left shadow-blue-600/60 text-shadow">
          Create With Us.
        </div>
        <div className="text-lg text-gray-700 dark:text-gray-400 dark:font-light w-10/12 sm:w-lg max-w-3xl mt-2 text-center">
          Create, mint and sell your own collections of NFTs on Dropspot. Manage
          your ongoing royalty and one-off sale splits. There is no cost to mint
          for verified creators.
        </div>
        <div className="flex justify-center my-2 gap-8">
          <div className="flex flex-col sm:flex-row justify-center mt-12 gap-4">
            <Link href={claims?.creator ? '/create' : '/create/onboard'}>
              <a className="bg-white dark:bg-gray-700 text-gray-900 border-gray-300 border dark:border-gray-600 dark:text-white text-lg rounded-full flex justify-center items-center px-9 py-3 hover:scale-105 duration-200 hover:-translate-y-1 hover:shadow-xl">
                {claims?.creator ? 'Manage Collections' : 'Already Invited?'}
              </a>
            </Link>
            {!claims?.creator && (
              <a
                onClick={() =>
                  window &&
                  window
                    .open(
                      'https://ef6vec2jxct.typeform.com/to/wVT4nWxS',
                      '_blank'
                    )
                    ?.focus()
                }
                className="cursor-pointer bg-white dark:bg-gray-700 text-gray-900 border-gray-300 border dark:border-gray-600 dark:text-white text-lg rounded-full flex justify-center items-center px-9 py-3 hover:scale-105 duration-200 hover:-translate-y-1 hover:shadow-xl"
              >
                Get Launchpad Access
              </a>
            )}
          </div>
        </div>
      </div>
      <Divider />
    </div>
  );
}
