import { ImageSwap } from '#components/Assets/DSImage';
import { NextImage } from '#components/Assets/NextImage';
import { ArrowTrendingDownIcon } from '@heroicons/react/24/outline';
import { ArrowTrendingUpIcon } from '@heroicons/react/24/solid';
import Link from 'next/link';
import cn from 'classnames';
import { Divider } from '#components/Divider/Divider';
import { NativeAssetRankings, Project } from '#types/index';
import Carousel from 'react-multi-carousel';
import { ProjectCardPublic } from '#components/Projects/ProjectCardPublic';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { getTop100_30D, getTop100_7D, getTop100_All } from '#lib/firestore';
import React from 'react';

type MarketTopTenProps = {
  displayType: 'TABLE' | 'CAROUSEL';
  marketTop10: NativeAssetRankings[];
};

export const MarketTrendingRegion = ({
  marketTop10,
  displayType,
}: MarketTopTenProps) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1600 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1600, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [selectedMode, setSelectedMode] = useState<
    '24h' | '7d' | '30d' | 'ALL'
  >('24h');

  const [trending24H, setTrending24H] = useState(marketTop10);
  const [trending7D, setTrending7D] = useState(marketTop10);
  const [trending30D, setTrending30D] = useState(marketTop10);
  const [trendingALL, setTrendingALL] = useState(marketTop10);

  const [displaySet, setDisplaySet] = useState(marketTop10);

  const getData = useCallback(async () => {
    console.log('>>>>>> get data');
    const top100_7D = await getTop100_7D();
    setTrending7D(top100_7D);

    const top100_30D = await getTop100_30D();
    setTrending30D(top100_30D.slice(0, 12));

    const top100_ALL = await getTop100_All();
    setTrendingALL(top100_ALL.slice(0, 12));
  }, []);

  useEffect(() => {
    getData().then(() => console.log('have done the data'));
  }, [getData]);

  useEffect(() => {
    console.log('selection changed');
    if (selectedMode == '24h') {
      setDisplaySet(trending24H);
    } else if (selectedMode == '7d') {
      setDisplaySet(trending7D);
    } else if (selectedMode == '30d') {
      setDisplaySet(trending30D);
    } else {
      setDisplaySet(trendingALL);
    }
  }, [selectedMode, trending24H, trending30D, trending7D, trendingALL]);

  return (
    <>
      {displayType == 'TABLE' ? (
        <>
          <div className="sm:px-[4rem]">
            <div className="">
              <span className="flex flex-col gap-2 my-3 sm:my-2 text-gray-700 dark:text-gray-300 px-3">
                <span className="flex flex-col sm:flex-row items-center justify-between mt-[2rem]">
                  <h2 className="text-lg sm:text-xl font-light text-gray-500 dark:text-gray-400">
                    VOLUME
                  </h2>
                </span>
                <Divider />
                <div className="w-full flex flex-col sm:flex-row items-center justify-between">
                  <h2 className="text-3xl sm:text-5xl font-bold dark:text-gray-100 sm:mt-6">
                    <Link href={`/marketplace`}>
                      <a>TRENDING </a>
                    </Link>
                  </h2>
                  <div className="btn-group">
                    <button
                      className={cn('btn btn-ghost', {
                        'btn-secondary text-info': selectedMode == '24h',
                      })}
                      onClick={() => setSelectedMode('24h')}
                    >
                      24H
                    </button>
                    <button
                      className={cn('btn  btn-ghost', {
                        'btn-secondary text-info': selectedMode == '7d',
                      })}
                      onClick={() => setSelectedMode('7d')}
                    >
                      7D
                    </button>
                    <button
                      className={cn('btn  btn-ghost', {
                        'btn-secondary text-info': selectedMode == '30d',
                      })}
                      onClick={() => setSelectedMode('30d')}
                    >
                      30D
                    </button>
                    <button
                      className={cn('btn  btn-ghost', {
                        'btn-secondary text-info': selectedMode == 'ALL',
                      })}
                      onClick={() => setSelectedMode('ALL')}
                    >
                      ALL
                    </button>
                  </div>
                </div>
              </span>
            </div>
            <div className="w-full grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 4xl:grid-cols-3 5xl:grid-cols-4 gap-2 sm:gap-6 my-12">
              {displaySet.slice(0, 12).map((ranking, idx) => (
                <React.Fragment key={ranking.name}>
                  <Link
                    key={idx}
                    href={
                      ranking.path
                        ? ranking.path.replace('collections', 'marketplace')
                        : ''
                    }
                  >
                    <a>
                      <div
                        className={cn(
                          ' flex flex-row items-center overflow-clip justify-between  border dark:border-gray-700/30 bg-base-300 rounded-xl sm:px-2  py-4',
                          {
                            'cursor-pointer': !!ranking.path,
                          },
                          {
                            'cursor-not-allowed': !ranking.path,
                          }
                        )}
                      >
                        <span className="flex flex-row gap-3 sm:gap-4 items-end my-3 ">
                          <span className="font-bold text-xl dark:text-gray-400 text-gray-600"></span>
                          <span className="relative pr-2">
                            {!!ranking.featureImage && (
                              <div className="rounded-xl overflow-hidden h-24 w-24 sm:h-60 sm:w-52   opacity-100 hover:grayscale-0 hover:opacity-100 duration-100 border-4 border-white dark:border-base-100 ">
                                <ImageSwap
                                  src={ranking.featureImage}
                                  alt={''}
                                  thumb={ranking.featureImageB64}
                                />
                              </div>
                            )}

                            {!ranking.featureImage && (
                              <div className="h-24 w-24 sm:h-60 sm:w-52 flex items-center justify-center ">
                                <div className="flex-none h-24 w-24 sm:w-42 sm:h-42 md:w-52 md:h-60 lg:w-40 2xl:w-52 lg:h-40 2xl:h-60  bg-base-200 rounded-xl">
                                  {!!ranking.logo && (
                                    <NextImage
                                      className="w-6 h-6  rounded-xl overflow-hidden object-cover shadow-2xl opacity-100 hover:grayscale-0 hover:opacity-100 duration-100"
                                      height={100}
                                      width={100}
                                      src={ranking.logo
                                        .toString()
                                        .replace(
                                          'ipfs://',
                                          'https://nftstorage.link/ipfs/'
                                        )}
                                      priority={false}
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                          </span>

                          <div className="flex flex-col w-full overflow-hidden">
                            <span className="sm:text-2xl font-bold">
                              {' '}
                              {idx + 1}.
                            </span>
                            <span className="flex flex-row items-center gap-1">
                              {ranking['sevenDayChange'] > 0 && (
                                <>
                                  <p className="text-green-500 text-sm">
                                    {' '}
                                    {(ranking['sevenDayChange'] * 100).toFixed(0)}%
                                  </p>
                                  <ArrowTrendingUpIcon className="text-green-500 h-4 w-4" />
                                </>
                              )}
                              {ranking['sevenDayChange'] < 0 && (
                                <>
                                  <p className="text-red-500 text-sm ">
                                    {' '}
                                    {(ranking['sevenDayChange'] * 100).toFixed(0)}%
                                  </p>
                                  <ArrowTrendingDownIcon className="text-red-500 h-4 w-4" />
                                </>
                              )}
                            </span>
                            <h2 className="dark:text-gray-200 sm:text-2xl font-bold ">
                              {ranking.name}
                            </h2>
                            <div className="my-1">
                              <Divider />
                            </div>
                            <div className="flex flex-row w-full gap-4 mt-2 mb-2">
                              <div className="flex flex-col">
                                <span className="sm:text-lg font-medium">
                                  ₳{ranking.price.toLocaleString()}
                                </span>
                                <span className="text-xs text-gray-500 dark:text-gray-400">
                                  FLOOR
                                </span>
                              </div>

                              <div className="flex flex-col">
                                <span className="sm:text-lg font-medium">
                                  ₳
                                  {ranking.volume7 &&
                                    ranking.volume7.toLocaleString()}
                                </span>
                                <span className="text-xs text-gray-500 dark:text-gray-400">
                                  VOL7D
                                </span>
                              </div>

                              <div className="flex flex-col">
                                <span className="sm:text-lg font-medium">
                                  {ranking['oneDayChange'] &&
                                  ranking['oneDayChange'].toFixed(0) != '0' &&
                                  ranking['oneDayChange'].toFixed(0) != '-0' ? (
                                    <span>
                                      {ranking['oneDayChange'].toFixed(0)}%
                                    </span>
                                  ) : (
                                    '-'
                                  )}
                                </span>
                                <span className="text-xs text-gray-500 dark:text-gray-400">
                                  24H
                                </span>
                              </div>
                            </div>
                          </div>
                        </span>
                      </div>
                    </a>
                  </Link>
                </React.Fragment>
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="sm:px-[4rem] mt-24">
            <div className="">
              <span className="flex flex-col gap-2 my-3 sm:my-2 text-gray-700 dark:text-gray-300 px-3">
                <span className="flex flex-row items-center justify-between mt-[2rem]">
                  <h2 className="text-lg sm:text-xl font-light text-gray-500 dark:text-gray-400">
                    VOLUME
                  </h2>
                </span>
                <Divider />
                <h2 className="text-3xl sm:text-5xl font-bold dark:text-gray-100 mt-6">
                  <Link href={`/marketplace`}>
                    <a>TRENDING</a>
                  </Link>
                </h2>
              </span>
            </div>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlaySpeed={5000}
              keyBoardControl={true}
              transitionDuration={300}
              autoPlay={true}
              arrows={true}
              renderButtonGroupOutside={true}
              containerClass="carousel-container"
              dotListClass="custom-dot-list-style"
              itemClass="px-1 sm:pl-2 sm:pr-6 sm:py-8"
            >
              {marketTop10.slice(0, 15).map((ranking, idx) => (
                <React.Fragment key={`${idx}${ranking.name}}`}>
                  <ProjectCardPublic
                    isComingSoon={false}
                    heightClass={'h-[40rem]'}
                    project={
                      {
                        name: ranking.name,
                        id: ranking.name,
                        heroImage: ranking.featureImage,
                      } as Project
                    }
                    key={`${idx}${ranking.name}}`}
                  />
                </React.Fragment>
              ))}
            </Carousel>
          </div>
        </>
      )}
    </>
  );
};
