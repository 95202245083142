import { CreatorNotificationPreferences } from '#types/index';
import firebase from 'firebase/app';
import 'firebase/messaging';
import React, { useState, useEffect } from 'react';
import { createNotificationPreferences } from './firestore';
import { firebaseConfig } from '#lib/firebase';
import constants from '#lib/constants';

export function getCloudMessagingToken(
  currentUserId: string,
  preferences: CreatorNotificationPreferences
) {
  console.log('In get cloud messaging token');
  let messaging: firebase.messaging.Messaging | null = null;
  if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging();
  }
  // [START messaging_get_token]
  // Get registration token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.
  if (!navigator) {
    console.log('no navigator');
    return;
  }
  if (!navigator.serviceWorker) {
    console.log('no service worker');
    return;
  }
  console.log('we have it');
  if ('serviceWorker' in navigator) {
    console.log('adding config: ', firebaseConfig);
    navigator.serviceWorker
      .register(`/fsw.js?firebaseConfig=${JSON.stringify(firebaseConfig)}`)
      .then(
        function (registration) {
          console.log(
            'Service Worker registration successful with scope firebase: ',
            registration.scope
          );
          if (!registration) {
            console.log('no registration');
            return;
          }
          messaging &&
            messaging
              .getToken({
                vapidKey: constants.CLOUD_MESSAGING_KEY,
                serviceWorkerRegistration: registration,
              })
              .then(async (currentToken) => {
                if (currentToken) {
                  // Send the token to your server and update the UI if necessary
                  // ...
                  console.log('we have a token: ', currentToken);
                  await createNotificationPreferences({
                    ...preferences,
                    cloudMessageToken: currentToken,
                    cloudMessage: true,
                    notifyOnOffer: true,
                    notifyOnCancel: true,
                    notifyOnList: true,
                    notifyOnSale: true,
                  });
                  console.log('firebase updated with cloud messaging token.');
                  return currentToken;
                } else {
                  // Show permission request UI
                  console.log(
                    'No registration token available. Request permission to generate one.'
                  );
                  requestPermission(currentUserId, preferences);
                  // ...
                }
              })
              .catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                // ...
              });
        },
        function (err) {
          console.log('Service Worker registration failed: ', err);
        }
      );
  }

  // [END messaging_get_token]
}

function requestPermission(
  currentUserId: string,
  preferences: CreatorNotificationPreferences
) {
  // [START messaging_request_permission]
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      // TODO(developer): Retrieve a registration token for use with FCM.
      // ...
      getCloudMessagingToken(currentUserId, preferences);
    } else {
      console.log('Unable to get permission to notify.');
    }
  });
  // [END messaging_request_permission]
}
