import { Asset, LiveTransaction, Project } from '#types/index';
import { ProjectCardPublic } from '#components/Projects/ProjectCardPublic';
import Link from 'next/link';
import Carousel from 'react-multi-carousel';
import { Divider } from '#components/Divider/Divider';
import { AssetFlipCardPublic } from '#components/Assets/AssetFlipCard/AssetFlipCardPublic';
import React from 'react';
import {
  useLiveAllTransactionListener,
  useLiveTransactionsByTypeListener,
} from '#lib/hooks/useProjectAssets';
import cn from 'classnames';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 7,
  },
  desktop: {
    breakpoint: { max: 3000, min: 2500 },
    items: 6,
  },
  desktop11: {
    breakpoint: { max: 2500, min: 1920 },
    items: 6,
  },
  desktop1: {
    breakpoint: { max: 1920, min: 1600 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1600, min: 1440 },
    items: 5,
  },
  tablet1: {
    breakpoint: { max: 1440, min: 1001 },
    items: 4,
  },
  tablet2: {
    breakpoint: { max: 1000, min: 801 },
    items: 3,
  },
  tablet3: {
    breakpoint: { max: 800, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function LiveAssetsSection() {
  const [selectedMode, setSelectedMode] = React.useState<
    'all' | 'listed' | 'sale'
  >('all');
  const { liveTransactions } = useLiveTransactionsByTypeListener(
    selectedMode,
    9
  );
  console.log('liveTransactions', liveTransactions);
  return (
    <div className="sm:px-[4rem] mt-12">
      <div className="">
        <span className="flex flex-col gap-2 my-3 sm:my-2 text-gray-700 dark:text-gray-300 px-3">
          <span className="flex flex-row items-center justify-between mt-[2rem]">
            <div className="badge badge-secondary badge-lg">LIVE</div>
          </span>
          <Divider />
          <h2 className="text-3xl sm:text-5xl font-bold dark:text-gray-100 sm:mt-6 w-full flex justify-between">
            <Link href={`/marketplace`}>
              <a>Action</a>
            </Link>
            <div className="btn-group">
              <button
                className={cn('btn  btn-ghost px-[6px] sm:px-4 ', {
                  'btn-secondary text-info': selectedMode == 'all',
                })}
                onClick={() => setSelectedMode('all')}
              >
                ALL
              </button>
              <button
                className={cn('btn  btn-ghost px-[6px] sm:px-4', {
                  'btn-secondary text-info': selectedMode == 'listed',
                })}
                onClick={() => setSelectedMode('listed')}
              >
                LISTINGS
              </button>
              <button
                className={cn('btn  btn-ghost px-[6px] sm:px-4', {
                  'btn-secondary text-info': selectedMode == 'sale',
                })}
                onClick={() => setSelectedMode('sale')}
              >
                SALES
              </button>
            </div>
          </h2>
          <div className="py-2 sm:mt-6">
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              keyBoardControl={true}
              autoPlaySpeed={6000}
              transitionDuration={500}
              arrows={true}
              renderButtonGroupOutside={true}
              containerClass="carousel-container"
              dotListClass="custom-dot-list-style"
              itemClass="px-1 md:pl-2 md:pr-4 sm:py-8"
              slidesToSlide={1}
            >
              {!!liveTransactions
                ? liveTransactions.map((transaction, idx) => (
                    <div
                      className="relative"
                      key={`${transaction.asset?.id}${idx}`}
                    >
                      {transaction.asset && (
                        <AssetFlipCardPublic
                          transaction={transaction}
                          asset={transaction.asset}
                          link={''}
                          heightClass="h-[24rem]  3xl:h-[28rem]"
                          showProjectInfo={true}
                          showSoldPrice={true}
                        />
                      )}
                    </div>
                  ))
                : [{}, {}, {}, {}, {}, {}, {}, {}, {}].map(
                    (transaction, idx) => (
                      <div className="relative" key={`${idx}`}>
                        <AssetFlipCardPublic
                          revealMode={true}
                          heightClass="h-[24rem]  3xl:h-[28rem]"
                          link="/asset/1"
                          showProjectInfo={true}
                          asset={{
                            algoliaProjectFacet: `{"pt":"https://via.placeholder.com/200}","title":"____"}`,
                            id: 'd',
                            projectId: 'd',
                            assetStandard: 'CIP25',
                            assetUrl: 'https://via.placeholder.com/200',
                            title: 'asset name',
                            creator: {
                              id: '123456',
                              userStatus: 'CREATOR',
                              creatorCategory: 'Other',
                              createdByAction: 'ADMIN',
                              turboTransactionsOn: true,
                              active: true,
                              public: true,
                              givenName: 'John',
                              profilePhoto: 'profile.jpg',
                              creationDate: 1678932000,
                              createdBy: 'admin',
                              lastUpdateDate: 1679018400,
                              lastUpdatedBy: 'admin',
                            },
                            description: 'asset description ibbjkjbkb',
                            category: 'Art',
                            status: 'Minted',
                            creationDate: 1,
                            createdBy: 'string',
                            lastUpdateDate: 0,
                            lastUpdatedBy: 'string',
                            files: [
                              {
                                src: 'https://via.placeholder.com/200',
                                name: 'asset',
                                mediaType: 'ONCHAIN-HTML',
                              },
                            ],
                          }}
                        />
                      </div>
                    )
                  )}
            </Carousel>
          </div>
        </span>
      </div>
    </div>
  );
}
