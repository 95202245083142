import Layout from '#components/TraderDashboard/Layout';
import {
  getCreator,
  getFrontPage,
  getMintedCreatorProjects,
  getProject,
  getTop100_24HR,
} from '#lib/firestore';
import {
  Asset,
  Collective,
  ComingSoonDisplay,
  FeaturedCreators,
  FrontPage,
  FrontPageCarousel,
  MintPhase,
  NativeAssetRankings,
  Project,
} from '#types/index';
import { getFirebaseAdmin } from 'next-firebase-auth';
import { useDegenStore } from '#lib/hooks/useGlobalMode';
import Head from 'next/head';
import algoliasearch from 'algoliasearch';
import constants from '#lib/constants';
import { useEffect, useState } from 'react';
import { HomePageMain } from '#components/TraderDashboard/HomePage/HomeMain';
import { DynamicAccountPrompter } from '#components/Account/DynamicAccountPrompter';
import { useFirebaseCurrentUser } from '#lib/hooks/useFirebaseCurrentUser';

type HomeProps = {
  heroCollections?: Project[];
  featuredCollections?: Project[];
  marketCollections?: Project[];
  superCubeCollections?: Project[];
  heroAssets?: Asset[];
  featuredAssets?: Asset[];
  marketTop10?: NativeAssetRankings[];
  comingSoon?: ComingSoonDisplay[];
  campaign?: Collective;
  bannerCarousel?: FrontPageCarousel[];
  featuredCreators?: FeaturedCreators[];
  mintingNowHero?: Project;
  mintPhases?: MintPhase[];
};

export default function Index({
  featuredAssets,
  featuredCollections,
  superCubeCollections,
  featuredCreators,
  marketTop10,
  comingSoon,
  campaign,
  bannerCarousel,
  mintingNowHero,
  mintPhases,
}: HomeProps) {
  const mode = useDegenStore((state) => state.degenMode);
  const searchClient = algoliasearch(
    '91RRC8EAH8',
    'f1f7d0552a938afdece75849a9b79abc'
  );
  const [inDegenMode, setInDegenMode] = useState(false);
  const { currentUser } = useFirebaseCurrentUser();

  //Wait till NextJS rehydration completes
  // useEffect(() => {
  //   setIsHydrated(true);
  // }, []);

  useEffect(() => {
    setInDegenMode(mode);
  }, [mode]);
  return (
    <>
      <Layout
        preview={false}
        selectedSubMenuIndex={0}
        hideSubNav={true}
        fullWidth={true}
        showFooter={true}
        translucent={true}
        miniFooter={false}
      >
        <Head>
          <title>Dropspot | NFT Marketplace</title>
        </Head>
        {!!currentUser && <DynamicAccountPrompter userId={currentUser.uid} />}
        <HomePageMain
          superCubeCollections={superCubeCollections}
          featuredAssets={featuredAssets}
          featuredCollections={featuredCollections}
          marketTop10={marketTop10}
          comingSoon={comingSoon}
          campaign={campaign}
          heroCarousel={bannerCarousel}
          featuredCreators={featuredCreators}
          mintingNowHero={mintingNowHero}
          mintingNowMintPhases={mintPhases}
        />
      </Layout>
    </>
  );
}

export const getStaticProps = async () => {
  const result = await getHomePage();

  return {
    props: { ...result },
  };
};

type categoryDisplay = {
  name: string;
  collections: Project[];
};

export const getHomePage: () => Promise<HomeProps> = async () => {
  const homeProps: HomeProps = {};
  const fbAdmin = getFirebaseAdmin();

  if (!fbAdmin) {
    throw new Error('FB Admin init error');
  }

  const fpData = await getFrontPage('V2');
  // const comingSoon = await getFrontPageComingSoon();
  // homeProps.comingSoon = comingSoon;
  if (!fpData) {
    throw new Error('FP Data record not found');
  }

  const top12_24H = await getTop100_24HR();
  if (!!top12_24H) {
    homeProps.marketTop10 = await Promise.all(
      top12_24H.slice(0, 12).map(async (x) => {
        let thumbnail = x.logo || '';
        if (!!thumbnail && typeof thumbnail === 'string') {
          if (thumbnail.startsWith('https://infura-ipfs.io/ipfs/')) {
            thumbnail = thumbnail.replace(
              'https://infura-ipfs.io/ipfs/',
              'ipfs://'
            );
          }

          if (thumbnail.startsWith('https://media.dropspot.io')) {
            thumbnail = thumbnail + '_400x400';
          }
        }

        return {
          ...x,
          thumbnail,
        };
      })
    );
  }

  // const campaign = await getCollectiveByShortName('iwd');
  // if (!!campaign) {
  //   homeProps.campaign = campaign;
  // }

  if (fpData.bannerCarousel) {
    const caoruselFrontPage = await fetchLimitedProjectsForFrontPage(fpData);
    homeProps.bannerCarousel = caoruselFrontPage.bannerCarousel;
  }

  if (fpData.nowMintingHeroProject) {
    const project = await getProject(fpData.nowMintingHeroProject);

    const db = fbAdmin.firestore();

    const result = await db
      .collection('Project')
      .doc(project.id)
      .collection('MintPhases')
      .orderBy('startDate', 'asc')
      .get();

    const mintPhases = result.docs.map((doc) => doc.data() as MintPhase);

    homeProps.mintingNowHero = project;
    homeProps.mintPhases = mintPhases;
  }

  if (fpData.featuredCreators) {
    const caoruselFrontPage = await fetchCreatorDetails(fpData);
    homeProps.featuredCreators = caoruselFrontPage.featuredCreators;
  }

  // if (
  //   fpData.superCubeCollections &&
  //   Array.isArray(fpData.superCubeCollections)
  // ) {
  //   homeProps.superCubeCollections = await Promise.all(
  //     fpData.superCubeCollections.map((e) => getProject(e))
  //   );
  // }

  if (fpData.featuredCollections && Array.isArray(fpData.featuredCollections)) {
    homeProps.featuredCollections = await Promise.all(
      fpData.featuredCollections.map((e) => getProject(e))
    );
  }

  // if (fpData.featuredAssets && Array.isArray(fpData.featuredAssets)) {
  //   homeProps.featuredAssets = await Promise.all(
  //     fpData.featuredAssets.map((e) => getAsset(e))
  //   );
  // }

  return homeProps;
};

async function fetchLimitedProjectsForFrontPage(
  frontPage: FrontPage
): Promise<FrontPage> {
  const updatedFrontPage: FrontPage = { ...frontPage };

  const updatedCarouselItemsPromises = frontPage.bannerCarousel
    .sort((a, b) => (a.order > b.order ? 1 : -1))
    .map(async (carouselItem) => {
      const projectItemsPromises = carouselItem.projects.map(
        async (projectId) => {
          const project = await getProject(projectId);
          const projectStripped = {
            name: project.name,
            id: project.id,
            heroImage: project.heroImage,
            heroImageB64: project.heroImageB64,
            featureImage: project.featureImage,
            bannerImage: project.bannerImage,
            tokenPrefix: project.tokenPrefix,
            bannerImageB64: project.bannerImageB64,
            creator: project.creator,
            videoCover: project.videoCover || null,
            playAsVideo: project.playAsVideo || null,
          } as Project;
          const updatedProject = {
            ...projectStripped,
            category: carouselItem.category,
          };
          return updatedProject;
        }
      );

      const projectItems = await Promise.all(projectItemsPromises);

      const updatedCarouselItem: FrontPageCarousel = {
        ...carouselItem,
        projectItems,
      };

      return updatedCarouselItem;
    });

  const updatedCarouselItems = await Promise.all(updatedCarouselItemsPromises);

  updatedFrontPage.bannerCarousel = updatedCarouselItems;

  return updatedFrontPage;
}
async function fetchCreatorDetails(frontPage: FrontPage): Promise<FrontPage> {
  const updatedFrontPage: FrontPage = { ...frontPage };
  if (!frontPage.featuredCreators) return frontPage;

  const updatedFeaturedCreatorsPromises = frontPage.featuredCreators.map(
    async (featuredCreator) => {
      const creator = await getCreator(featuredCreator.creatorId);
      const projects = await getMintedCreatorProjects(
        featuredCreator.creatorId
      );

      const updatedFeaturedCreator: FeaturedCreators = {
        ...featuredCreator,
        creator,
        projects,
      };

      return updatedFeaturedCreator;
    }
  );

  const updatedFeaturedCreators = await Promise.all(
    updatedFeaturedCreatorsPromises
  );

  updatedFrontPage.featuredCreators = updatedFeaturedCreators;

  return updatedFrontPage;
}
