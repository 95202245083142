import {
  BellAlertIcon,
  CheckIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/solid';
import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';

import { useCreatorNotificationPreferences } from '#lib/hooks/useCreator';
import { getCloudMessagingToken } from '#lib/firebaseMessaging';


type DynamicPromptProps = {
  userId: string;
};

export const DynamicAccountPrompter = ({ userId }: DynamicPromptProps) => {
  const [shouldShowPrompt, setShouldShowPrompt] = useState(false);
  const [enablingBrowserNotifications, setEnablingBrowserNotifications] =
    useState(false);
  const [notifsEnabled, setNotifsEnabled] = useState(false);
  const { preferences, loading } =
    useCreatorNotificationPreferences({
      creatorId: userId,
    });

  useEffect(() => {
    console.log('We have prefs:', preferences);
    if (!preferences && !loading) {
      setShouldShowPrompt(true);
    }
    if (!!preferences && !preferences.cloudMessage) {
      setShouldShowPrompt(true);
    }
  }, [loading, preferences]);

  const enableBrowserNotifications = useCallback(async () => {
    console.log('will enable browserr notifications');
    setEnablingBrowserNotifications(true);
    if (!preferences || !userId) {
      return;
    }
    const cmToken = getCloudMessagingToken(userId, preferences);
    setEnablingBrowserNotifications(false);

    setNotifsEnabled(true);
    setTimeout(() => {
      setShouldShowPrompt(false);
    }, 3000);
  }, [userId, preferences]);

  return (
    <>
      <div
        onClick={() => enableBrowserNotifications()}
        className={cn(
          'z-30 bg-base-300 p-3 rounded-xl shadow-lg fixed top-24 flex transition-all duration-300 cursor-pointer',
          { 'right-12': shouldShowPrompt },
          { '-right-60': !shouldShowPrompt }
        )}
      >
        <div className="flex flex-row gap-2 font-normal items-center w-44">
          {notifsEnabled ? (
            <CheckIcon className="w-5 text-green-600" />
          ) : (
            <DaisyComponent />
          )}

          <span className="flex flex-row justify-between items-center w-full">
            {enablingBrowserNotifications ? (
              <>
                <span> Activating...</span>
                <span className="loading loading-spinner loading-xs"></span>
              </>
            ) : (
              <>
                {' '}
                {notifsEnabled ? 'Activated' : 'Activate alerts'}
                <InformationCircleIcon className="w-5" />
              </>
            )}
          </span>
        </div>
      </div>
    </>
  );
};

const DaisyComponent: React.FC = () => {
  const [isChecked, setIsChecked] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsChecked((prevIsChecked) => !prevIsChecked);
    }, 5000); // Change the interval duration (in milliseconds) as needed

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <label className={`swap  text-sm ${isChecked ? 'swap-active' : ''}`}>
      {/* this hidden checkbox controls the state */}
      <input type="checkbox" checked={isChecked} readOnly />

      <BellAlertIcon
        className={cn('w-5 text-info animate-wiggle', {
          isChecked: 'animate-wiggle',
        })}
      />
    </label>
  );
};
