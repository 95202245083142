import { Project } from '#types/index';
import { ProjectCardPublic } from '#components/Projects/ProjectCardPublic';
import Link from 'next/link';
import Carousel from 'react-multi-carousel';
import { Divider } from '#components/Divider/Divider';
import DaisyButton from '#components/Daisy/DaisyButton';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { ProjectDropCardPublic } from '#components/Projects/ProjectDropCardPublic';

type SpotlightProps = {
  spotlightCollections: Project[];
  indexDisplay?: number;
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1920 },
    items: 4,
  },
  desktop1: {
    breakpoint: { max: 1920, min: 1600 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1600, min: 1440 },
    items: 3,
  },

  tablet1: {
    breakpoint: { max: 1440, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
export default function MintingSection({
  spotlightCollections,
}: SpotlightProps) {
  return (
    <div className="sm:px-[4rem] mt-12">
      <div className="">
        <span className="flex flex-col gap-2 my-3 sm:my-2 text-gray-700 dark:text-gray-300 px-3">
          <span className="flex flex-row items-center justify-between sm:mt-[2rem]">
            <div className="badge badge-accent badge-lg">Launchpad</div>
          </span>
          <Divider />
          <div className="sm:mt-6 w-full flex items-center justify-between">
            <h2 className="text-3xl sm:text-5xl font-bold dark:text-gray-100 ">
              <Link href={`/marketplace`}>
                <a>DROPS</a>
              </Link>
            </h2>
            <Link href={`/marketplace?category=1:1`}>
              <a
                role="button"
                className="btn btn-ghost flex items-center gap-2"
              >
                Explore <ArrowRightIcon className="w-4" />
              </a>
            </Link>
          </div>
          <div className="py-2 sm:mt-6">
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              keyBoardControl={true}
              autoPlaySpeed={6000}
              transitionDuration={500}
              arrows={true}
              renderButtonGroupOutside={true}
              containerClass="carousel-container"
              dotListClass="custom-dot-list-style"
              itemClass="px-1 sm:pl-2 sm:pr-12 sm:py-8"
              slidesToSlide={1}
            >
              {spotlightCollections?.map((collection, idx) => (
                <ProjectDropCardPublic
                  project={collection}
                  heightClass={'h-[30rem] 2xl:h-[40rem]'}
                  playAsVideo={collection.playAsVideo}
                  key={`${collection.id}${idx}`}
                  projectmint={true}
                />
              ))}
            </Carousel>
          </div>
        </span>
      </div>
    </div>
  );
}
