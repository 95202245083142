export function getURLForSrc(
  src?: string,
  width?: number,
  quality?: number
): string {
  let filteredSrc = src;

  const widthToOptimise = width || 200;
  const qualityToUse = quality || 1.0;
  const params = [`width=${widthToOptimise}`];
  if (quality) {
    params.push(`quality=${qualityToUse}`);
  }
  const paramsString = params.join(',');

  if (
    !filteredSrc ||
    filteredSrc === 'undefined' ||
    filteredSrc === 'null' ||
    filteredSrc === 'holding'
  ) {
    filteredSrc =
      'https://media.dropspot.io/placeholders%2FNo-Image-Placeholder.svg.png';
  }

  if (!filteredSrc.startsWith('data')) {
    filteredSrc = `https://dropspot.io/cdn-cgi/image/${paramsString}/${normalizeSrc(
      src ||
        'https://media.dropspot.io/placeholders%2FNo-Image-Placeholder.svg.png'
    )}`;
  }

  console.log('Have constructed the URL: ', filteredSrc);
  return filteredSrc;
}

const normalizeSrc = (src: string) => {
  return src && src.startsWith('/') ? src.slice(1) : src;
};
