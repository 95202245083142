import {
  Asset,
  Collective,
  ComingSoonDisplay,
  FeaturedCreators,
  FrontPageCarousel,
  MintPhase,
  NativeAssetRankings,
  Project,
} from '#types/index';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'react-multi-carousel/lib/styles.css';
import CallToActionCreators from './CallToActionCreators';
import { TheOne } from './TheOne';
import { MarketTrendingRegion } from '../MarketTrendingRegion';
import cn from 'classnames';
import { useInView } from 'react-intersection-observer';
import LiveAssetsSection from './LiveAssetsSection';
import MintingSection from './MintingSection';
import { RaffleWidget } from './RaffleWidget';
import { NowMintingHero } from './NowMintingHero';

import Ticker from 'react-ticker';
import { getPartnerProjects, getSuperCubProjects } from '#lib/firestore';
import { NextImage } from '#components/Assets/NextImage';
import Link from 'next/link';
export interface ProjectCardPublicProps {
  project: Project;
}

type HomeProps = {
  featuredCollections?: Project[];
  featuredAssets?: Asset[];
  superCubeCollections?: Project[];
  marketTop10?: NativeAssetRankings[];
  comingSoon?: ComingSoonDisplay[];
  campaign?: Collective;
  heroCarousel?: FrontPageCarousel[];
  featuredCreators?: FeaturedCreators[];
  mintingNowHero?: Project;
  mintingNowMintPhases?: MintPhase[];
};

dayjs.extend(relativeTime);

export const HomePageMain = ({
  superCubeCollections,
  heroCarousel,
  marketTop10,
  featuredAssets,
  featuredCollections,
  featuredCreators,
  mintingNowHero,
  mintingNowMintPhases,
}: HomeProps) => {
  //console.log('heroCollections', heroCollections);
  const [showRaffle, setShowRaffle] = useState(true);
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.95,
    initialInView: true,
  });

  useEffect(() => {
    console.log('intersection in view');
    if (!inView) {
      setShowRaffle(false);
    } else {
      //   setPinned(false);
      console.log('>>>>>>>>>>>>IN THE VIEW  >>>>>>>>>>>>>>');
      setShowRaffle(true);
    }
  }, [inView]);

  return (
    <div className="w-full px-1">
      {/* Narrow sidebar*/}
      {/* {mintingNowHero && mintingNowMintPhases && (
        <NowMintingHero
          project={mintingNowHero}
          mintPhases={mintingNowMintPhases}
        />
      )} */}
      <span className="-mobile" ref={ref}>
        <TheOne heroCarousel={heroCarousel} />
      </span>
      <div className="w-full">
        <StockTicker />
      </div>
      <LiveAssetsSection />
      {/* <RaffleWidget /> */}
      {featuredCollections && (
        <MintingSection spotlightCollections={featuredCollections} />
      )}
      {marketTop10 && (
        <MarketTrendingRegion displayType={'TABLE'} marketTop10={marketTop10} />
      )}

      {/* <DroppingSoonHero
        project={
          {
            id: 'seedsoffreedom',
            name: 'Seeds of Freedom',
            featureImage: 'https://media.dropspot.io/drops%2Fdyana-cover.gif',
            bannerImage:
              'https://www.dyana.org/wp-content/uploads/2021/03/ocean-light.jpg',
          } as Project
        }
      /> */}
      {/* {superCubeCollections && (
        <ZeroFeesSection spotlightCollections={superCubeCollections} />
      )} */}
      {/* {featuredCreators && (
        <FeaturedCreatorsSection featuredCreators={featuredCreators} />
      )} */}
      {/* {featuredCollections && (
        <SpotLightSection spotlightCollections={featuredCollections} />
      )} */}
      {/* {featuredAssets && (
        <FeaturedAssetsSection featuredAssets={featuredAssets} />
      )} */}

      {/* <CampaignSection campaignShortName="PRIDE" /> */}
      <CallToActionCreators />
      <div
        className={cn('w-full duration-500  flex justify-end', {
          'opacity-0': !showRaffle,
        })}
      >
        {/* <CSSTransition
          in={showRaffle}
          timeout={500} // Duration of the fade-out transition
          classNames="fade"
          unmountOnExit
        >
          <RaffleView />
        </CSSTransition> */}
      </div>
    </div>
  );
};

const GetProjectsFromAPI = () => {
  const [projects, setProjects] = useState<Project[] | undefined>();

  useEffect(() => {
    async function fetchData() {
      // Fetch project data, including images and names
      const projectsFromAPI = await getPartnerProjects(40);
      setProjects(projectsFromAPI);
    }
    fetchData();
  }, []);

  return (
    <div className="flex flex-shrink flex-nowrap whitespace-nowrap">
      {projects && projects.length > 0 ? (
        projects.map((project, index) => (
          <div
            key={index}
            className="project-item flex flex-row gap-3 mr-6 items-center hover:-translate-y-1 transition py-3 opacity-50 hover:opacity-100 group"
          >
            <div className="w-10 h-10 rounded-xl overflow-hidden relative group-hover:scale-125 transition duration-200">
              <NextImage
                layout="fill"
                width={100}
                src={project.heroImage}
                priority={false}
                b64={project.heroImageB64}
              />
            </div>
            <Link href={`/marketplace/${project.tokenPrefix}`}>
              <a className="font-medium tracking-tighter text-lg text-shadow shadow-gray-400/30 transition duration-300">
                {' +++ '}
                {project.name} {' +++ '}
              </a>
            </Link>
          </div>
        ))
      ) : (
        <p style={{ visibility: 'hidden' }}>Placeholder</p>
      )}
    </div>
  );
};

function StockTicker() {
  return (
    <Ticker offset="run-in" speed={10}>
      {() => <GetProjectsFromAPI />}
    </Ticker>
  );
}
